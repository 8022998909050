export const API_BASE_URL = "https://taxi-api.offplus.ir/";
// export const API_BASE_URL = "http://localhost:8008/";

export function setToken(userToken) {
	localStorage.setItem("token", JSON.stringify(userToken));
}

export function getToken() {
	const tokenString = localStorage.getItem("token");
	const userToken = JSON.parse(tokenString);
	return userToken?.token;
}

const listeners = {};

export function subscribe(event, callback) {
	if (!listeners[event]) {
		listeners[event] = [];
	}
	listeners[event].push(callback);
}

export function emit(event, data) {
	if (listeners[event]) {
		listeners[event].forEach((callback) => callback(data));
	}
}

export function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}
